import { Corn } from '@eigtech/shared-corn'
import { useGetAssignmentsForEntities } from '@eigtech/ui-shared-assignments'
import { getContactRoleLabel } from '@eigtech/ui-shared-contacts'
import {
  Box,
  Button,
  ButtonGroup,
  Stack,
  Tag,
  Text,
  Tooltip,
  Wrap,
  WrapItem,
  forwardRef,
  getAllObjectPaths,
} from '@eigtech/ui-shared-dave'
import { InputField, TextareaField, useFormContext, useWatch } from '@eigtech/ui-shared-forms'
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  BeautifulMentionComponentProps,
  BeautifulMentionsMenuItemProps,
  InlineMention,
  LexicalEditor,
  Mention,
  MentionsInputField,
  MentionsMenuItem,
  useBeautifulMentions,
} from '@eigtech/ui-shared-mentions'
import { get, startCase } from 'lodash-es'
import { useCallback, useEffect, useRef } from 'react'
import { TaggableContact, useGetTaggableContacts } from '../../hooks/useGetTaggableContacts'
import { NoteFieldsType, isNoteMention } from './NoteFieldsSchema'
import { isISOish, useDatesContext } from '@eigtech/ui-shared-dates'

export type NoteFieldsProps = {
  entityCorn: Corn | Corn[]
  templateParams?: Record<string, unknown>
}

export function NoteFields({ entityCorn, templateParams }: NoteFieldsProps) {
  const { preferredFormatDateFunction } = useDatesContext()

  const { getValues, setValue, control } = useFormContext<NoteFieldsType>()

  const taggableContacts = useGetTaggableContacts({ entityCorn })

  const queries = useGetAssignmentsForEntities([entityCorn].flat())

  const taggableLookup = taggableContacts.map(({ roles, ...taggableContact }) => {
    const contactAssignments = queries
      .flatMap(({ data }) => data ?? [])
      .filter(
        (assignment) =>
          assignment.assignee.type === 'contact' &&
          assignment.assignee.assigneeId === taggableContact.id
      )
      .map((assignment) => startCase(assignment.assignable.assigneeRelationship))
      .join('|')

    return {
      ...taggableContact,
      roles: JSON.stringify(roles),
      lookup: `${roles.join('|')}|${contactAssignments}|${taggableContact.value}`.toLowerCase(),
    }
  })

  const paramsLookup = getAllObjectPaths(templateParams ?? {})
    .filter((path) => !!get(templateParams ?? {}, path))
    .map((path) => {
      const baseValue = String(get(templateParams ?? {}, path))
      const value = isISOish(baseValue)
        ? preferredFormatDateFunction({ dateTime: baseValue, property: path })
        : baseValue

      return {
        value,
        path,
        param: value,
      }
    })

  const enableMentions = !!(taggableContacts?.length || paramsLookup.length)

  const editorRef = useRef<LexicalEditor>(null)

  const template = useWatch({ control, name: 'template' })

  // Programmatically update the mentions input when template selection changes
  // Only update if template has been selected and template has a text template
  useEffect(() => {
    // Don't need to do programatic update if mentions are not enabled
    if (!enableMentions) return

    if (!template) return
    if (!('textTemplate' in template)) return
    if (!template.textTemplate) return

    editorRef.current?.update(() => {
      const root = $getRoot()
      root.clear()

      const paragraphNode = $createParagraphNode()

      // Intentionally using the actual `text` value of the form here
      // This will have the compiled template params
      const textNode = $createTextNode(getValues().text)

      paragraphNode.append(textNode)
      root.append(paragraphNode)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template])

  const getTaggable = async (trigger: string, query?: string | null) => {
    switch (trigger) {
      case '#':
        return !query
          ? paramsLookup
          : paramsLookup.filter((t) =>
              t.path.toLowerCase().trim().includes(query.toLowerCase().trim())
            )

      default:
        return !query
          ? taggableLookup
          : taggableLookup.filter((t) => t.lookup.includes(query.toLowerCase()))
    }
  }

  const onMentionsChange = useCallback(
    (mentions: Mention[]) => {
      setValue('mentions', mentions.filter(isNoteMention))
    },
    [setValue]
  )

  return (
    <>
      <InputField label="Note Title" name="title" />

      {enableMentions ? (
        <Box
          flexGrow={1}
          sx={{
            '.chakra-form-control': {
              height: 'full',
              display: 'flex',
              flexDir: 'column',
              'div:has(.mentions-input)': {
                flexGrow: 1,
                height: 'full',
                '.mentions-input': {
                  height: 'full',
                },
              },
            },
          }}
        >
          <MentionsInputField
            ref={editorRef}
            MentionComponent={CustomMentionComponent}
            label="Note Text"
            mentionsProps={{ flexGrow: 1, flexShrink: 0, minHeight: 52 }}
            menuItemComponent={CustomMentionsMenuItem}
            name="text"
            triggers={triggers}
            onMentionsChange={onMentionsChange}
            onSearch={getTaggable}
          >
            <MentionsActions showInsertParameter={!!paramsLookup.length} />
          </MentionsInputField>
        </Box>
      ) : (
        <TextareaField label="Note Text" name="text" rows={12} />
      )}
    </>
  )
}

const CustomMentionsMenuItem = forwardRef<BeautifulMentionsMenuItemProps, 'li'>(
  function CustomMentionsMenuItem({ item, ...props }, ref) {
    const roles =
      typeof item.data?.roles === 'string' &&
      (JSON.parse(item.data.roles) as TaggableContact['roles'])

    return (
      <MentionsMenuItem ref={ref} item={item} {...props} minW="sm">
        {!!item.data && 'path' in item.data ? (
          <Stack spacing="0.5">
            <Text as="span">{item.data.path}</Text>
            <Text as="span" color="gray.500" fontSize="sm">
              {item.data.param}
            </Text>
          </Stack>
        ) : (
          <Stack spacing="0.5">
            <Text as="span">{item.data?.name ?? item.displayValue}</Text>
            {Array.isArray(roles) && (
              <Wrap spacing="1">
                {roles.map(getContactRoleLabel).map((role) => (
                  <WrapItem key={role}>
                    <Tag size="sm">{role}</Tag>
                  </WrapItem>
                ))}
              </Wrap>
            )}
          </Stack>
        )}
      </MentionsMenuItem>
    )
  }
)

function MentionsActions({ showInsertParameter }: { showInsertParameter: boolean }) {
  const { openMentionMenu, hasMentions } = useBeautifulMentions()

  if (!hasMentions) return null

  return (
    <ButtonGroup justifyContent="flex-end" mb="2" size="xs" spacing="1" variant="outline">
      <Button onClick={() => openMentionMenu({ trigger: '@' })}>Insert Mention</Button>
      {!!showInsertParameter && (
        <Button onClick={() => openMentionMenu({ trigger: '#' })}>Insert Parameter</Button>
      )}
    </ButtonGroup>
  )
}

const CustomMentionComponent = forwardRef<BeautifulMentionComponentProps, 'span'>(
  function CustomMentionComponent(
    { trigger: __trigger, value: __value, data, children, ...props },
    ref
  ) {
    const name = (data as { name: string } | undefined)?.name
    const path = (data as { path: string } | undefined)?.path

    return (
      <Tooltip isDisabled={!path} label={path}>
        <InlineMention ref={ref} {...props}>
          {!!name ? `@${name}` : children}
        </InlineMention>
      </Tooltip>
    )
  }
)

const triggers = ['@', '#']
