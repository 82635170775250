import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { ContactSchema } from '../../../base/contacts.js'

export const DeleteContactDescriptionRequestPathSchema = z.object({
  contactId: ContactSchema.shape.contactId,
})
export const DeleteContactDescriptionRequestHeadersSchema =
  ApiLambdalerAuthHeaderWithRequestActorSchema.pick({ requestActor: true })

export const DeleteContactDescriptionRequestSchema =
  DeleteContactDescriptionRequestPathSchema.merge(DeleteContactDescriptionRequestHeadersSchema)

export const DeleteContactDescriptionResponseSchema = z.void()

export type DeleteContactDescriptionRequestPath = z.infer<
  typeof DeleteContactDescriptionRequestPathSchema
>
export type DeleteContactDescriptionRequest = z.infer<typeof DeleteContactDescriptionRequestSchema>

export type DeleteContactDescriptionResponse = z.infer<
  typeof DeleteContactDescriptionResponseSchema
>

export interface DeleteContactDescriptionHandler
  extends Handler<DeleteContactDescriptionRequest, DeleteContactDescriptionResponse> {}

export const DeleteContactDescriptionApiSchema: ApiSchema = {
  headers: DeleteContactDescriptionRequestHeadersSchema,
  params: DeleteContactDescriptionRequestPathSchema,
}
