import {
  UpdateContactRequest,
  UpdateContactRequestBody,
  UpdateContactResponse,
} from '@eigtech/contacts-types'
import { contextualPut, useMutation } from '@eigtech/ui-shared-api'
import { contactBasePath } from './constants'
import { useInvalidateBooksDetails } from './getBooks'
import { useInvalidateContact } from './getContacts'

const updateContact = ({ contactId, ...request }: Omit<UpdateContactRequest, 'requestActor'>) =>
  contextualPut<UpdateContactResponse, UpdateContactRequestBody>(
    `${contactBasePath}/${contactId}`,
    request,
    { responseType: 'none' }
  )

export function useUpdateContact() {
  const invalidateContact = useInvalidateContact()
  const invalidateBooks = useInvalidateBooksDetails()

  return useMutation({
    mutationFn: updateContact,
    onSuccess(__, { contactId }) {
      invalidateContact(contactId)
      invalidateBooks()
    },
  })
}
