import { CohortEnum } from '@eigtech/auth0-types'
import { ContactCorn, isContactCorn } from '@eigtech/contacts-types'
import { Corn } from '@eigtech/shared-corn'
import { AddNoteRequest } from '../api/createEntityNote'
import { NoteFieldsType } from '../components/CreateNote/NoteFieldsSchema'
import { compileNoteTemplate, formatNewLines, stripTemplateParamTagWrappers } from './formatMessage'

export type MakeAddNotePayloadProps = {
  entityCorn: Corn
  publishOnCreate?: boolean
  source: AddNoteRequest['category']
  cohort: CohortEnum
  templateParams?: Record<string, unknown>
} & NoteFieldsType

export function makeAddNotePayload({
  entityCorn,
  cohort,
  source,
  text,
  publishOnCreate,
  title,
  mentions,
  template,
  templateParams,
}: MakeAddNotePayloadProps) {
  const ids = mentions
    ?.map(({ data }) => data.id)
    .filter((id): id is ContactCorn => isContactCorn(id ?? ''))

  const payload: AddNoteRequest = {
    category: source,
    dataSource: source,
    entityCorn,
    taggedContacts: ids,
    title,
    userCohorts: [cohort],
    text: compileNoteTemplate({
      note: stripTemplateParamTagWrappers(formatNewLines(text)),
      templateParams,
    }),
    ...(publishOnCreate && { publicationTargets: ['XactAnalysis'] }),
  }

  if (template) {
    payload.templateId = template.id
  }

  return payload
}
