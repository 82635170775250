import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { BookCornSchema } from '../../../base/books.js'
import { ContactCornSchema } from '../../../base/contacts.js'

export const AddContactToBookRequestPathParametersSchema = z.object({
  bookId: BookCornSchema,
})
export const AddContactToBookRequestBodySchema = z.object({
  contactId: ContactCornSchema,
})
export const AddContactToBookRequestHeadersSchema =
  ApiLambdalerAuthHeaderWithRequestActorSchema.pick({ requestActor: true })
export const AddContactToBookRequestSchema = AddContactToBookRequestPathParametersSchema.merge(
  AddContactToBookRequestBodySchema
).merge(AddContactToBookRequestHeadersSchema)

export type AddContactToBookRequestPathParameters = z.infer<
  typeof AddContactToBookRequestPathParametersSchema
>
export type AddContactToBookRequestHeaders = z.infer<typeof AddContactToBookRequestHeadersSchema>
export type AddContactToBookRequestBody = z.infer<typeof AddContactToBookRequestBodySchema>
export type AddContactToBookRequest = z.infer<typeof AddContactToBookRequestSchema>

export type AddContactToBookResponse = void

export interface AddContactToBookHandler
  extends Handler<AddContactToBookRequest, AddContactToBookResponse> {}

export const AddContactToBookApiSchema: ApiSchema = {
  body: AddContactToBookRequestBodySchema,
  params: AddContactToBookRequestPathParametersSchema,
  headers: AddContactToBookRequestHeadersSchema,
}
