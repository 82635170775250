import { Chronicle } from '@eigtech/summarizer-types'
import { randomString } from '@eigtech/ui-shared-dave'
import { exhaustive } from 'exhaustive'
import { SetOptional } from 'type-fest'

export type AddTimelineFilterOnSubmit = (data: { filter: FilterFn; label: string }) => void

export type FilterFn = (chronicle: Chronicle) => boolean
export type FilterFns = FilterFn[]

export type FilterFnType = 'event' | 'createdBy' | 'date' | 'search'

export type FilterFnId = `${FilterFnType}_${string}`

export type FilterFnStateItem = {
  filter: FilterFn
  label: string
  type: FilterFnType
  id: FilterFnId
  hideFilter?: boolean
  data?: Record<string, any>
}

export type FilterFnsState = Map<FilterFnId, FilterFnStateItem>

export type FilterFnsStateActions =
  | { type: 'update'; payload: SetOptional<FilterFnStateItem, 'id'> }
  | { type: 'remove'; payload: FilterFnId }

export const initialTimelineFilterState = new Map() satisfies FilterFnsState

export function timelineFilterStateReducer(state: FilterFnsState, action: FilterFnsStateActions) {
  return exhaustive.tag(action, 'type', {
    update({ payload }): FilterFnsState {
      const filterFns = new Map(state)

      const id = payload.id ?? (`${payload.type}_${randomString()}` satisfies FilterFnId)

      filterFns.set(id, {
        id,
        ...payload,
      })

      return filterFns
    },

    remove: ({ payload }): FilterFnsState => {
      const filterFns = new Map(state)
      filterFns.delete(payload)

      return filterFns
    },
  })
}
