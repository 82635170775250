import { ComposedModal, ComposedModalProps } from '@eigtech/ui-shared-dave'
import { ReactNode } from 'react'
import { FieldValues, SubmitHandler } from 'react-hook-form'
import { z } from 'zod'
import { UseFormReturn } from '../useForm'

export type FormModalProps<
  T extends z.Schema<any, any>,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
> = ComposedModalProps & {
  form: UseFormReturn<T, TContext, TTransformedValues>
  submitButton?: ReactNode
  onSubmit:
    | (TTransformedValues extends FieldValues
        ? SubmitHandler<TTransformedValues>
        : SubmitHandler<z.infer<T>>)
    | undefined
}

export function FormModal<
  T extends z.Schema<any, any>,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
>({
  children,
  form,
  submitButton,
  onSubmit,
  ...props
}: FormModalProps<T, TContext, TTransformedValues>) {
  const {
    formState: { isSubmitting },
  } = form

  return (
    <ComposedModal
      closeButtonProps={{ isDisabled: isSubmitting }}
      footerActionsRight={
        !submitButton || typeof submitButton === 'string' ? (
          <form.SubmitButton>{submitButton}</form.SubmitButton>
        ) : (
          submitButton
        )
      }
      footerCloseButtonProps={{ isDisabled: isSubmitting }}
      {...props}
    >
      {!!onSubmit ? <form.Form onSubmit={onSubmit}>{children}</form.Form> : children}
    </ComposedModal>
  )
}
