import {
  JobAssigneeRelationship,
  JobAssigneeRelationshipEnumSchema,
} from '@eigtech/assignments-types'
import {
  DailyServicesServiceSchema,
  EmergencyServicesServiceSchema,
  JobIdSchema,
  JobLocationAddressTypeSchema,
  JobLocationGeoCoordinatesSchema,
  JobTypeSchema,
  WorkAuthorizationSchema,
  WorkAuthorizationStatusEnumSchema,
} from '@eigtech/restoration-types'
import z from 'zod'
import { makeSummaryFilterSchema, makeSummaryParamsSchema } from './pagination'

export const GenericJobAssigneeSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.optional(z.string()),
  phone: z.optional(z.string()),
  rawAddress: z.optional(z.string()),
  street1: z.optional(z.string()),
  street2: z.optional(z.string()),
  city: z.optional(z.string()),
  state: z.optional(z.string()),
  zipcode: z.optional(z.string()),
})

export const JobLocationSchema = z.object({
  addressLine1: z.string(),
  addressLine2: z.optional(z.string()),
  addressLine3: z.optional(z.string()),
  city: z.optional(z.string()),
  stateOrProvince: z.optional(z.string()),
  zipOrPostalCode: z.optional(z.string()),
  country: z.optional(z.string()),
  addressType: z.optional(JobLocationAddressTypeSchema),
  countyOrParishCode: z.optional(z.string()),
  geoAccuracy: z.optional(JobLocationGeoCoordinatesSchema.shape['accuracy']),
  latitude: z.optional(z.string()),
  longitude: z.optional(z.string()),
})

export const JobWorkAuthSchema = WorkAuthorizationSchema.omit({
  workAuthorizationId: true,
  approvedBy: true,
  signer: true,
  effectiveDate: true,
}).merge(
  z.object({
    workAuthId: WorkAuthorizationSchema.shape.workAuthorizationId,
    approvedBy: WorkAuthorizationSchema.shape.approvedBy.optional(),
    signer: WorkAuthorizationSchema.shape.signer.optional(),
    effectiveDate: WorkAuthorizationSchema.shape.effectiveDate.optional(),
  })
)

export const JobSummarySchema = z.object({
  id: JobIdSchema,
  description: z.string().optional(),
  type: JobTypeSchema,
  isClosed: z.boolean(),
  closedReason: z.string().optional(),
  relatedClaimNumber: z.string().optional(),
  latestTimelineEvent: z.string().optional(),
  latestWorkAuthStatus: WorkAuthorizationStatusEnumSchema.optional(),
  jobLocation: JobLocationSchema.optional(),
  services: z
    .enum([...EmergencyServicesServiceSchema.options, ...DailyServicesServiceSchema.options])
    .array(),
  primaryContact: GenericJobAssigneeSchema.optional(),
  // workAuth: SummarizerJobWorkAuthSchema.optional(),
  projectManager: GenericJobAssigneeSchema.optional(),
  subcontractors: GenericJobAssigneeSchema.array().optional(),
  fieldTechnicians: GenericJobAssigneeSchema.array().optional(),
})

export const JobSummaryFieldSchema = z.enum([
  'city',
  'closedReason',
  'county',
  'description',
  'id',
  'isClosed',
  'latestTimelineEvent',
  'approverId',
  'approverEmail',
  'approverName',
  'approverPhone',
  'coordinatorId',
  'coordinatorEmail',
  'coordinatorName',
  'coordinatorPhone',
  'dispatcherId',
  'dispatcherEmail',
  'dispatcherName',
  'dispatcherPhone',
  'latestWorkAuthStatus',
  'primaryContactEmail',
  'primaryContactName',
  'primaryContactPhone',
  'projectManagerId',
  'projectManagerEmail',
  'projectManagerName',
  'projectManagerPhone',
  'relatedClaimNumber',
  'state',
  'type',
  'services',
  'workAuthSignedOn',
  //'workAuthApprovedBy',
  //'workAuthEffectiveDate',
  //'workAuthRecipient',
  //'workAuthSentDate',
  //'workAuthSentMethod',
  //'workAuthSigner',
  //'workAuthId',
])

export const JobSummaryFilterSchema = makeSummaryFilterSchema(JobSummaryFieldSchema)

export const JobSummariesParamsSchema = makeSummaryParamsSchema(
  JobSummaryFieldSchema,
  JobSummaryFilterSchema
)

export const JobMonoAssignmentRelationshipSchema = JobAssigneeRelationshipEnumSchema.extract([
  'approver',
  'coordinator',
  'dispatcher',
  'primaryContact',
  'projectManager',
])
export const isJobMonoAssignmentRelationship = (relationship: JobAssigneeRelationship) =>
  JobMonoAssignmentRelationshipSchema.safeParse(relationship).success

export const JobPolyAssignmentRelationshipSchema = JobAssigneeRelationshipEnumSchema.extract([
  'fieldTechnician',
  'subcontractor',
])
export const isJobPolyAssignmentRelationship = (relationship: JobAssigneeRelationship) =>
  JobPolyAssignmentRelationshipSchema.safeParse(relationship).success

export type GenericJobAssignee = z.infer<typeof GenericJobAssigneeSchema>
export type JobLocation = z.infer<typeof JobLocationSchema>
export type JobWorkAuth = z.infer<typeof JobWorkAuthSchema>
export type JobSummaryFilter = z.infer<typeof JobSummaryFilterSchema>
export type JobSummariesParams = z.infer<typeof JobSummariesParamsSchema>
export type JobSummaryField = z.infer<typeof JobSummaryFieldSchema>
export type JobSummary = z.infer<typeof JobSummarySchema>
export type JobMonoAssignmentRelationship = z.infer<typeof JobMonoAssignmentRelationshipSchema>
export type JobPolyAssignmentRelationship = z.infer<typeof JobPolyAssignmentRelationshipSchema>
