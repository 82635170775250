import { NewTableRow, Schema, TableRow, defineTable } from 'squid'

export type NewClaimRecord = NewTableRow<typeof claimTable>
export type ClaimRecord = TableRow<typeof claimTable>

const claimTable = defineTable('claim', {
  carrier_id: Schema.nullable(Schema.String),
  claim_number: Schema.String,
  claim_status: Schema.nullable(Schema.String),
  contacts_book_id: Schema.nullable(Schema.String),
  coordinator_id: Schema.nullable(Schema.String),
  field_adjuster_id: Schema.nullable(Schema.String),
  has_legal_rep: Schema.nullable(Schema.Boolean),
  initial_claim_actions: Schema.nullable(Schema.Array(Schema.String)),
  inspection_technician_id: Schema.nullable(Schema.String),
  latest_timeline_event: Schema.nullable(Schema.String),
  notebook_id: Schema.nullable(Schema.String),
  policy_holder_id: Schema.nullable(Schema.String),
  policy_number: Schema.nullable(Schema.String),
  primary_contact_id: Schema.nullable(Schema.String),
  project_manager_id: Schema.nullable(Schema.String),
  reviewer_id: Schema.nullable(Schema.String),
  verisk_id: Schema.nullable(Schema.String),
})
