import {
  ComposedMenu,
  HStack,
  MenuItem,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Wrap,
  WrapItem,
  useDisclosure,
} from '@eigtech/ui-shared-dave'
import { useDetailedTimelineContext } from './DetailedTimeline'
import { CreatedByFilterModal } from './TimelineFilters/CreatedByFilterModal'
import { DateFilterModal } from './TimelineFilters/DateFilterModal'
import { EventFilterModal, EventFilterModalProps } from './TimelineFilters/EventFilterModal'
import { SearchFilter } from './TimelineFilters/SearchFilter'

export type DetailedTimelineFiltersProps = {
  filterableEvents: EventFilterModalProps['events']
}

export function DetailedTimelineFilters({ filterableEvents }: DetailedTimelineFiltersProps) {
  const {
    isOpen: isOpenEventFilterModal,
    onOpen: onOpenEventFilterModal,
    onClose: onCloseEventFilterModal,
  } = useDisclosure()
  const {
    isOpen: isOpenCreatedByFilterModal,
    onOpen: onOpenCreatedByFilterModal,
    onClose: onCloseCreatedByFilterModal,
  } = useDisclosure()
  const {
    isOpen: isOpenDateFilterModal,
    onOpen: onOpenDateFilterModal,
    onClose: onCloseDateFilterModal,
  } = useDisclosure()

  return (
    <>
      <Stack spacing="4">
        <HStack>
          <SearchFilter />

          <ComposedMenu trigger="Add Filter">
            <MenuItem onClick={onOpenCreatedByFilterModal}>Created By</MenuItem>
            <MenuItem onClick={onOpenDateFilterModal}>Date</MenuItem>
            <MenuItem onClick={onOpenEventFilterModal}>Timeline Event</MenuItem>
          </ComposedMenu>
        </HStack>

        <FiltersList />
      </Stack>

      {isOpenEventFilterModal && (
        <EventFilterModal
          events={filterableEvents}
          isOpen={isOpenEventFilterModal}
          onClose={onCloseEventFilterModal}
        />
      )}

      {isOpenCreatedByFilterModal && (
        <CreatedByFilterModal
          isOpen={isOpenCreatedByFilterModal}
          onClose={onCloseCreatedByFilterModal}
        />
      )}

      {isOpenDateFilterModal && (
        <DateFilterModal isOpen={isOpenDateFilterModal} onClose={onCloseDateFilterModal} />
      )}
    </>
  )
}

function FiltersList() {
  const { filters: filtersState, setFilters } = useDetailedTimelineContext()

  const filters = [...filtersState.values()].filter((filter) => !filter.hideFilter)

  if (!filters.length) {
    return null
  }

  return (
    <Wrap>
      {filters.map((data) => (
        <WrapItem key={data.id}>
          <Tag colorScheme="blue">
            <TagLabel>{data.label}</TagLabel>
            {/* <IconButton
        _hover={{
          opacity: 1,
        }}
        aria-label="Edit"
        color="brandPrimary.1000"
        icon={<MdEdit />}
        ml="2"
        opacity={0.5}
        size="xs"
        variant="ghost"
        onClick={}
      /> */}
            <TagCloseButton
              ml="0"
              onClick={() => {
                setFilters({
                  type: 'remove',
                  payload: data.id,
                })
              }}
            />
          </Tag>
        </WrapItem>
      ))}
    </Wrap>
  )
}
