import type {
  AddContactToBookRequestBody,
  AddContactToBookRequestPathParameters,
  AddContactToBookResponse,
  CreateContactRequest,
  CreateContactResponse,
} from '@eigtech/contacts-types'
import { contextualPost, useMutation } from '@eigtech/ui-shared-api'
import { contactBasePath } from './constants'
import { useInvalidateBook } from './getBooks'

const createContact = async ({
  bookId,
  ...createContactRequest
}: Omit<CreateContactRequest, 'requestActor'> & Partial<AddContactToBookRequestPathParameters>) => {
  const { contactId } = await contextualPost<CreateContactResponse>(
    contactBasePath,
    createContactRequest
  )

  if (!!bookId) {
    const addContactRequest: AddContactToBookRequestBody = { contactId }

    await contextualPost<AddContactToBookResponse>(
      `${contactBasePath}/${bookId}`,
      addContactRequest,
      {
        responseType: 'none',
      }
    )
  }

  return { bookId, contactId }
}

export function useCreateContact() {
  const invalidateBook = useInvalidateBook()

  return useMutation({
    mutationFn: createContact,
    onSuccess(__, { bookId }) {
      if (!!bookId) {
        invalidateBook(bookId)
      }
    },
  })
}
