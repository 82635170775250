import {
  DATE_INPUT_FORMAT,
  addDays,
  isAfter,
  isBefore,
  isSameDay,
  subDays,
  useDatesContext,
} from '@eigtech/ui-shared-dates'
import { ComposedModalWrapperProps } from '@eigtech/ui-shared-dave'
import { FormModal, createForm } from '@eigtech/ui-shared-forms'
import { exhaustive } from 'exhaustive'
import { startCase } from 'lodash-es'
import { z } from 'zod'
import { useDetailedTimelineContext } from '../DetailedTimeline'
import { FilterFn } from './timelineFilterState'

export type DateFilterModalProps = ComposedModalWrapperProps

export function DateFilterModal(modalProps: DateFilterModalProps) {
  const { preferredFormatDateFunction } = useDatesContext()

  const { setFilters } = useDetailedTimelineContext()

  const form = useForm()

  function handleSubmit({ type, date }: FormData) {
    const filter: FilterFn = (chronicle) => {
      const convertedDateString = preferredFormatDateFunction({
        dateTime: chronicle.date,
        format: DATE_INPUT_FORMAT,
        property: 'date',
      })
      const convertedDate = new Date(convertedDateString)
      const inputDate = new Date(date)

      return exhaustive(type, {
        'on or after': () => isAfter(convertedDate, subDays(inputDate, 1)),
        'on or before': () => isBefore(convertedDate, addDays(inputDate, 1)),
        exactly: () => isSameDay(convertedDate, inputDate),
      })
    }

    setFilters({
      type: 'update',
      payload: {
        filter,
        label: `Event date is ${type} ${date}`,
        type: 'date',
      },
    })

    modalProps.onClose()
  }

  return (
    <FormModal {...modalProps} form={form} title="Add Created By Filter" onSubmit={handleSubmit}>
      <SelectField
        label="Filter Type"
        name="type"
        options={TypeSchema.options.map((value) => ({ value, label: startCase(value) }))}
      />
      <InputField label="Date" name="date" type="date" />
    </FormModal>
  )
}

const TypeSchema = z.enum(['exactly', 'on or before', 'on or after'])

const FormSchema = z.object({
  type: TypeSchema,
  date: z.string().min(1, 'Date is required'),
})

type FormData = z.infer<typeof FormSchema>

const { useForm, SelectField, InputField } = createForm(FormSchema, 'eventFilterForm')
