import {
  List,
  ListItem,
  ListItemProps,
  Skeleton,
  StyleProps,
  Text,
  Textarea,
  chakra,
  forwardRef,
} from '@eigtech/ui-shared-dave'
import log from '@eigtech/ui-shared-logging'
import { $generateHtmlFromNodes } from '@lexical/html'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import DOMPurify from 'dompurify'
import { EditorState, LexicalEditor } from 'lexical'
import {
  BeautifulMentionComponentProps,
  BeautifulMentionsItem,
  BeautifulMentionsMenuItemProps,
  BeautifulMentionsMenuProps,
  BeautifulMentionsPlugin,
  createBeautifulMentionNode,
} from 'lexical-beautiful-mentions'
import {
  ElementType,
  ForwardedRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from 'react'
import { Mention } from './types/Mention'
import { getMentionsFromEditorState } from './utils'

type BaseMentionsInputProps =
  | {
      items: Record<string, BeautifulMentionsItem[]>
      triggers?: never
      onSearch?: never
      searchDelay?: never
    }
  | {
      items?: never
      triggers: string[]
      onSearch: (trigger: string, queryString?: string | null) => Promise<BeautifulMentionsItem[]>
      searchDelay?: number
    }

export type MentionsInputProps = StyleProps &
  BaseMentionsInputProps & {
    autofocus?: boolean
    children?: ReactNode
    editorRef?: ForwardedRef<LexicalEditor>
    isDisabled?: boolean
    isReadOnly?: boolean
    menuItemComponent?: ElementType<BeautifulMentionsMenuItemProps>
    MentionComponent?: ElementType<BeautifulMentionComponentProps>
    onChange?: (value: string, editorState: EditorState) => any
    onMentionsChange?: (mentions: Mention[]) => any
  }

export const MentionsInput = forwardRef<MentionsInputProps, 'div'>(function MentionsInput(
  {
    autofocus,
    children,
    editorRef: propsEditorRef,
    isDisabled,
    isReadOnly,
    items,
    menuItemComponent,
    MentionComponent,
    searchDelay,
    triggers,
    onChange,
    onMentionsChange,
    onSearch,
    ...props
  },
  ref
) {
  const editorRef = useRef<LexicalEditor>(null!)
  useImperativeHandle(propsEditorRef, () => editorRef.current)

  const mentionsProps = { items, triggers, onSearch, searchDelay } as BaseMentionsInputProps

  const handleChange = useCallback(() => {
    const editorState = editorRef.current.getEditorState()

    if (onChange) {
      editorState.read(() => {
        // convert editor nodes to HTML and strip all HTML except for br tags
        // in the future we could potentially leave more tags in if
        // we want to support more HTML, or support editing notes
        const htmlString = $generateHtmlFromNodes(editorRef.current)
        const value = mentionsPurify.sanitize(htmlString, { ALLOWED_TAGS: ['br'] })

        onChange(value, editorState)
      })
    }

    if (onMentionsChange) {
      const mentions = getMentionsFromEditorState(editorState)
      onMentionsChange(mentions)
    }
  }, [onChange, onMentionsChange])

  return (
    <chakra.div
      ref={ref}
      className="mentions-input"
      display="flex"
      flexDir="column"
      position="relative"
      {...props}
      onBlur={(e) => {
        handleChange()
        props.onBlur?.(e)
      }}
    >
      <LexicalComposer initialConfig={makeEditorConfig({ MentionComponent })}>
        {children}
        <chakra.div display="flex" flexDir="column" flexGrow={1} position="relative">
          <RichTextPlugin
            ErrorBoundary={LexicalErrorBoundary}
            contentEditable={
              <Textarea
                as={ContentEditable}
                flexGrow={1}
                isDisabled={isDisabled}
                isReadOnly={isReadOnly}
                overflow="auto"
              />
            }
            placeholder={
              <Text
                color="fg.subtle"
                left="4"
                opacity={0.6}
                pointerEvents="none"
                position="absolute"
                top="2"
              >
                Enter some text...
              </Text>
            }
          />
          <BeautifulMentionsPlugin
            creatable={false}
            emptyComponent={Empty}
            insertOnBlur={false}
            menuComponent={MentionsMenu}
            menuItemComponent={menuItemComponent ?? MentionsMenuItem}
            menuItemLimit={100}
            {...mentionsProps}
          />

          {autofocus && <AutoFocusPlugin defaultSelection="rootStart" />}
          {/* Currently we only support calling the onChange prop on blur,
            but we could do it on change to if needed. */}
          {/* <OnChangePlugin onChange={handleChange} /> */}
          <HistoryPlugin />
          <EditorRefPlugin editorRef={editorRef} />
        </chakra.div>
      </LexicalComposer>
    </chakra.div>
  )
})

const MentionsMenu = forwardRef<BeautifulMentionsMenuProps, 'ul'>(function MentionsMenu(
  { loading, children, ...other },
  ref
) {
  return (
    <List
      ref={ref}
      {...other}
      bg="white"
      border="1px"
      borderColor="chakra-border-color"
      borderRadius="md"
      boxShadow="sm"
      m="0"
      maxH="96"
      minW="3xs"
      overflow="hidden"
      overflowY="auto"
      position="relative"
      px="2"
      py="1"
      top="2px"
      w="fit-content"
      zIndex={2000}
    >
      {loading ? <Skeleton my="1">Loading...</Skeleton> : children}
    </List>
  )
})

export const MentionsMenuItem = forwardRef<BeautifulMentionsMenuItemProps, 'li'>(
  function MentionsMenuItem(
    { selected, item: __item, itemValue: __itemValue, label: __label, ...props },
    ref
  ) {
    const itemRef = useRef<HTMLLIElement>(null)

    useImperativeHandle(ref, () => itemRef.current)

    // Set up an intersection observer so that when a user uses the keyboard arrows
    // to navigate through the mentions menu, we will always keep the selected
    // menu item in view
    useLayoutEffect(() => {
      let observer: IntersectionObserver | null = null

      const item = itemRef.current
      if (!item) return
      if (selected !== true) return

      const parent = item.parentElement
      if (!parent) return

      observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0]
          if (!entry.isIntersecting) {
            entry.target.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
            })
          }
        },
        {
          root: parent,
          rootMargin: '0px',
          threshold: 1,
        }
      )

      observer.observe(item)

      return () => {
        observer?.disconnect()
        observer = null
      }
    }, [selected])

    return (
      <BaseListItem
        ref={itemRef}
        {...(selected
          ? {
              bg: 'gray.100',
            }
          : {
              bg: 'white',
            })}
        {...props}
      />
    )
  }
)

const BaseListItem = forwardRef<ListItemProps, 'li'>(function BaseListItem(props, ref) {
  return (
    <ListItem
      ref={ref}
      alignItems="center"
      borderRadius="sm"
      cursor="pointer"
      display="flex"
      my="1"
      position="relative"
      px="3"
      py="1.5"
      textAlign="start"
      transitionDuration="50ms"
      transitionProperty="background-color,background-image,background-position"
      userSelect="none"
      {...props}
    />
  )
})

function Empty() {
  return (
    <MentionsMenu>
      <BaseListItem>No results found</BaseListItem>
    </MentionsMenu>
  )
}

export const InlineMention = forwardRef<
  Pick<BeautifulMentionComponentProps, 'children'> & StyleProps,
  'span'
>(function InlineMention({ children, ...props }, ref) {
  return (
    <chakra.span ref={ref} bg="blue.100" borderRadius="sm" p="0.5" {...props}>
      {children}
    </chakra.span>
  )
})

const DefaultMentionComponent = forwardRef<BeautifulMentionComponentProps, 'span'>(
  function DefaultMentionComponent(
    { trigger: __trigger, value: __value, data: __data, children, ...props },
    ref
  ) {
    return (
      <InlineMention ref={ref} {...props}>
        {children}
      </InlineMention>
    )
  }
)

function makeEditorConfig({
  MentionComponent = DefaultMentionComponent,
}: { MentionComponent?: ElementType<BeautifulMentionComponentProps> } = {}) {
  const editorConfig = {
    namespace: 'mentionsInput',
    nodes: [...createBeautifulMentionNode(MentionComponent)],
    onError(error: any) {
      log.error('MentionsInput error', { error })
      throw error
    },
  } satisfies InitialConfigType

  return editorConfig
}

// Make a custom instance of DOMPurify so that we can add a hook just for this component
const mentionsPurify = DOMPurify()
mentionsPurify.addHook('uponSanitizeElement', (node) => {
  // We want to wrap all mentions with [], this makes it easier
  // to support spaces in the names of people who are tagged
  if (node.tagName?.toLocaleLowerCase() === 'span') {
    const spanNode = node as HTMLSpanElement
    if (spanNode.dataset.lexicalBeautifulMention === 'true') {
      spanNode.textContent = `[${spanNode.textContent}]`
      return
    }
  }

  if (node.tagName?.toLocaleLowerCase() !== 'p') {
    return
  }

  // if paragraph already contains a line break, ignore it
  if (node.children.length === 1) {
    if (node.children[0].tagName.toLocaleLowerCase() === 'br') {
      return
    }
  }

  // paragraphs will be removed, so we need to add a line break in their stead
  node.insertAdjacentElement('afterend', document.createElement('br'))
})
