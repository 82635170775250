import { ComposedModalWrapperProps, formatArrayAsStringList } from '@eigtech/ui-shared-dave'
import { FormModal, createForm } from '@eigtech/ui-shared-forms'
import { z } from 'zod'
import { useDetailedTimelineContext } from '../DetailedTimeline'
import { FilterFn } from './timelineFilterState'

export type EventFilterModalProps = ComposedModalWrapperProps & {
  events: { label: string; value: string }[]
}

export function EventFilterModal({ events, ...modalProps }: EventFilterModalProps) {
  const { setFilters } = useDetailedTimelineContext()

  const form = useForm()

  function handleSubmit(data: FormData) {
    const values = data.event.map(({ value }) => value)
    const filter: FilterFn = (chronicle) => values.includes(chronicle.type)

    const labels = data.event.map(({ label }) => `"${label}"`)

    setFilters({
      type: 'update',
      payload: {
        filter,
        label: `Event Type is ${formatArrayAsStringList(labels, { type: 'disjunction' })}`,
        type: 'event',
      },
    })
    modalProps.onClose()
  }

  return (
    <FormModal {...modalProps} form={form} title="Add Event Filter" onSubmit={handleSubmit}>
      <ReactSelectField isMulti label="Select an Event" name="event" options={events} />
    </FormModal>
  )
}

const FormSchema = z.object({
  event: z.object({ label: z.string(), value: z.string() }).array(),
})

type FormData = z.infer<typeof FormSchema>

const { useForm, ReactSelectField } = createForm(FormSchema, 'eventFilterForm')
