import {
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@eigtech/ui-shared-dave'
import { MdClose, MdSearch } from 'react-icons/md'
import { useDetailedTimelineContext } from '../DetailedTimeline'
import { FilterFn } from './timelineFilterState'

export type SearchFilterProps = {}

export function SearchFilter() {
  const { filters, setFilters } = useDetailedTimelineContext()

  const value = filters.get('search_global')?.data?.value ?? ''

  function onChange(value: string) {
    if (!value) {
      setFilters({
        type: 'remove',
        payload: 'search_global',
      })
    } else {
      const filter: FilterFn = (chronicle) =>
        JSON.stringify(Object.values(chronicle)).toLowerCase().includes(value.trim().toLowerCase())

      const label = `Event includes "${value}"`

      setFilters({
        type: 'update',
        payload: {
          id: 'search_global',
          filter,
          label,
          type: 'search',
          hideFilter: true,
          data: {
            value,
          },
        },
      })
    }
  }

  return (
    <InputGroup maxW="96">
      <InputLeftElement>
        <MdSearch />
      </InputLeftElement>

      <Input placeholder="Search for..." value={value} onChange={(e) => onChange(e.target.value)} />

      {!!value && (
        <InputRightElement>
          <IconButton
            aria-label="Clear search"
            icon={<Icon as={MdClose} />}
            size="sm"
            variant="ghost"
            onClick={() => onChange('')}
          />
        </InputRightElement>
      )}
    </InputGroup>
  )
}
