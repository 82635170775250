export function getAllObjectPaths(
  obj: Record<string, unknown>,
  currentPath: string[] = []
): string[] {
  let paths: string[] = []

  for (const key in obj) {
    const newPath = [...currentPath, key]

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      paths = paths.concat(getAllObjectPaths(obj[key] as Record<string, unknown>, newPath))
    } else {
      paths.push(newPath.join('.'))
    }
  }

  return paths
}
