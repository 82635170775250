import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { ContactCornSchema, ContactSchema } from '../../../base/contacts.js'

export const SetExternalIdsRequestPathSchema = z.object({ contactId: ContactCornSchema })
export const SetExternalIdsRequestHeadersSchema = ApiLambdalerAuthHeaderWithRequestActorSchema.pick(
  { requestActor: true }
)
export const SetExternalIdsRequestBodySchema = z
  .object({
    externalIds: ContactSchema.shape.externalSystemIds,
  })
  .required()

export const SetExternalIdsRequestSchema = SetExternalIdsRequestBodySchema.merge(
  SetExternalIdsRequestPathSchema
).merge(SetExternalIdsRequestHeadersSchema)

export type SetExternalIdsPath = z.infer<typeof SetExternalIdsRequestPathSchema>
export type SetExternalIdsRequestBody = z.infer<typeof SetExternalIdsRequestBodySchema>
export type SetExternalIdsRequest = z.infer<typeof SetExternalIdsRequestSchema>

export type SetExternalIdsResponse = void

export interface SetExternalIdsHandler
  extends Handler<SetExternalIdsRequest, SetExternalIdsResponse> {}

export const SetExternalIdsApiSchema: ApiSchema = {
  body: SetExternalIdsRequestBodySchema,
  headers: SetExternalIdsRequestHeadersSchema,
  params: SetExternalIdsRequestPathSchema,
}
