import { CornSchema } from '@eigtech/shared-corn'
import { z } from 'zod'
import { AttendeeSchema, MeetingSchema } from '../base/chime.js'
import { MeetingDetailsSchema } from '../base/meetingDetails.js'
import { MeetingInviteSchema } from '../base/meetingInvite.js'
import { MeetingsServiceEventBaseSchema, serviceEventType } from './service.js'

export const meetingEventType = <V extends string | undefined>(eventName?: V) =>
  serviceEventType('meeting', eventName)

export const MeetingEventTypeNameSchema = z.enum([
  'started',
  'ended',
  'joined',
  'inviteSent',
  'descriptionUpdated',
])
export type MeetingEventTypeNameEnum = z.infer<typeof MeetingEventTypeNameSchema>
export const MeetingEventTypeName = MeetingEventTypeNameSchema.Enum

export const MeetingEventSchema = MeetingsServiceEventBaseSchema.merge(
  z.object({
    type: meetingEventType(),
    meeting: z.unknown(),
  })
)

export const MeetingStartedEventSchema = MeetingsServiceEventBaseSchema.merge(
  z.object({
    type: meetingEventType(MeetingEventTypeName.started),
    meeting: z.object({
      meetingDetails: MeetingDetailsSchema,
      chimeMeeting: MeetingSchema,
      chimeAttendee: AttendeeSchema,
    }),
  })
)

export const MeetingEndedEventSchema = MeetingsServiceEventBaseSchema.merge(
  z.object({
    type: meetingEventType(MeetingEventTypeName.ended),
    meeting: MeetingDetailsSchema,
  })
)

export const MeetingJoinedEventSchema = MeetingsServiceEventBaseSchema.merge(
  z.object({
    type: meetingEventType(MeetingEventTypeName.joined),
    meeting: z.object({
      meetingDetails: MeetingDetailsSchema,
      chimeMeeting: MeetingSchema,
      chimeAttendee: AttendeeSchema,
    }),
  })
)

export const MeetingInviteSentEventSchema = MeetingsServiceEventBaseSchema.merge(
  z.object({
    type: meetingEventType(MeetingEventTypeName.inviteSent),
    meeting: MeetingInviteSchema,
  })
)

export const MeetingUpdatedEventSchema = MeetingsServiceEventBaseSchema.merge(
  z.object({
    type: meetingEventType(MeetingEventTypeName.descriptionUpdated),
    meeting: z.object({
      entityId: CornSchema,
      meetingId: z.string(),
      startTime: z.string().datetime(),
      description: z.string(),
    }),
  })
)

export type MeetingStartedEvent = z.infer<typeof MeetingStartedEventSchema>
export type MeetingEndedEvent = z.infer<typeof MeetingEndedEventSchema>
export type MeetingJoinedEvent = z.infer<typeof MeetingJoinedEventSchema>
export type MeetingInviteSentEvent = z.infer<typeof MeetingInviteSentEventSchema>
export type MeetingUpdatedEvent = z.infer<typeof MeetingUpdatedEventSchema>
