import log from '@eigtech/ui-shared-logging'
import { template } from 'lodash-es'

export function formatMessage(message: string) {
  try {
    return message
      .replace(/&nbsp;/g, '') // cut back space chars
      .replace(/!\[CDATA\[/g, 'Information: ') //kill the cdata header
      .replace(/\]\]/g, '\n') //kill the cdata trailert
      .replace(/&#(x?)(\d+);/g, function ($0, $1, $2) {
        return String.fromCharCode(parseInt($2, $1 ? 16 : 10))
      }) // replace ASCII literals cause
      .trim() //trim the result
  } catch (error) {
    log.error('note format message error', { error })
    return message
  }
}

// Convert new lines to breaking spaces to persist line breaks
export function formatNewLines(text: string) {
  return text.replace(/\n/g, '<br />')
}

// Convert breaking spaces to new lines to persist line breaks
export function formatBreakingSpaces(text: string) {
  return text.replace(/<br ?\/?>/g, '\n')
}

export function stripTemplateParamTagWrappers(text: string) {
  return text.replace(/\[#(.*?)\]/g, (_, match) => match).trim()
}

export function compileNoteTemplate({
  note,
  templateParams,
}: {
  note: string
  templateParams: Record<string, unknown> | undefined
}) {
  if (!(note && templateParams)) return note

  const compiledTemplate = template(note)
  const compiledBody = compiledTemplate(templateParams)

  return compiledBody
}
