import { GetTimelineRequest, GetTimelineResponse } from '@eigtech/summarizer-types'
import { OmitAuthHeaders } from '@eigtech/ui-shared-api'
import { Stack } from '@eigtech/ui-shared-dave'
import { Dispatch, ReactNode, createContext, useContext, useReducer } from 'react'
import { useGetTimeline } from '../api/getTimeline'
import {
  FilterFnsState,
  FilterFnsStateActions,
  initialTimelineFilterState,
  timelineFilterStateReducer,
} from './TimelineFilters/timelineFilterState'

export type DetailedTimelineContext = {
  filters: FilterFnsState
  setFilters: Dispatch<FilterFnsStateActions>
  timeline: GetTimelineResponse | undefined
  isPending: boolean
  isError: boolean
} & Omit<OmitAuthHeaders<GetTimelineRequest>, 'filter'>

const DetailedTimelineContext = createContext<DetailedTimelineContext | undefined>(undefined)

export function useDetailedTimelineContext() {
  const context = useContext(DetailedTimelineContext)

  if (!context) {
    throw new Error('DetailedTimeline required to use useDetailedTimelineContext')
  }

  return context
}

export type DetailedTimelineProps = {
  children: ReactNode
} & Pick<DetailedTimelineContext, 'entityId' | 'entityType' | 'timelineType'>

export function DetailedTimeline({
  children,
  entityId,
  entityType,
  timelineType,
}: DetailedTimelineProps) {
  const [filters, setFilters] = useReducer(timelineFilterStateReducer, initialTimelineFilterState)

  const {
    data: timeline,
    isPending,
    isError,
  } = useGetTimeline({ entityId, entityType, timelineType })

  return (
    <DetailedTimelineContext.Provider
      value={{
        entityId,
        entityType,
        filters,
        isError,
        isPending,
        setFilters,
        timeline,
        timelineType,
      }}
    >
      <Stack spacing="6">{children}</Stack>
    </DetailedTimelineContext.Provider>
  )
}
