import { HStack, Text } from '@eigtech/ui-shared-dave'
import { EventDetailsComponentProps } from '@eigtech/ui-shared-timeline'
import { MeetingDetails } from '@eigtech/meetings-types'
import { useDatesContext } from '@eigtech/ui-shared-dates'
import { Link, useRoutesContext } from '@eigtech/ui-shared-router'

export function ClaimMeetingDetails(props: EventDetailsComponentProps<MeetingDetails>) {
  const {
    details: { meetingId, startTime, endTime },
  } = props

  const { PreferredDateTimeComponent: DateComponent } = useDatesContext()
  const { claimDetailMeeting } = useRoutesContext()
  return (
    <HStack>
      <Text as="span">
        {!!claimDetailMeeting ? (
          <Link
            to={claimDetailMeeting({
              claimNumber: props.entityId,
              meetingStartTime: startTime,
              meetingId,
            })}
          >
            Meeting{' '}
          </Link>
        ) : (
          'Meeting '
        )}
        occurred from <DateComponent property="startTime">{startTime}</DateComponent> to{' '}
        <DateComponent property="endTime">{endTime}</DateComponent>
      </Text>
    </HStack>
  )
}
