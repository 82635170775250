import { DeactivateContactRequest, DeactivateContactResponse } from '@eigtech/contacts-types'
import { contextualPut, useMutation } from '@eigtech/ui-shared-api'
import { contactBasePath } from './constants'
import { useInvalidateGlobalBooks } from './getBooks'
import { useInvalidateContact } from './getContacts'

const deactivateContact = ({ contactId }: Omit<DeactivateContactRequest, 'requestActor'>) =>
  contextualPut<DeactivateContactResponse>(
    `${contactBasePath}/${contactId}/deactivate`,
    undefined,
    { responseType: 'none' }
  )

export function useDeactivateContact() {
  const invalidateContact = useInvalidateContact()
  const invalidateBooks = useInvalidateGlobalBooks()

  return useMutation({
    mutationFn: deactivateContact,
    onSuccess(__, { contactId }) {
      invalidateContact(contactId)
      invalidateBooks()
    },
  })
}
