import { Book, Contact, ContactName, CreateContactRequest } from '@eigtech/contacts-types'
import log from '@eigtech/ui-shared-logging'
import { ContactForm } from '../ContactFormSchema'
import { mapAddressesToContact, mapContactToAddresses } from './mapContactAddresses'
import { mapContactToEmails, mapEmailsToContact } from './mapContactEmails'
import { mapContactToPhones, mapPhonesToContact } from './mapContactPhones'
import { mapContactToRoles, mapRolesToContact } from './mapContactRoles'

export function mapApiContactToFormContact(
  contact: Partial<Contact>,
  book: Book | undefined
): Partial<ContactForm> {
  const name = contact.name?.find((name) => !!name.givenName)
  const emails = mapContactToEmails(contact)
  const phones = mapContactToPhones(contact)
  const primaryEmailIndex = emails.findIndex((e) => e.preferenceOrder === 0)
  const primaryPhoneIndex = phones.findIndex((e) => e.preferenceOrder === 0)

  const contactForm: Partial<ContactForm> = {
    givenName: name?.givenName ?? '',
    familyName: name?.familyName?.surname,
    roles: mapContactToRoles(contact, book),
    addresses: mapContactToAddresses(contact),
    emails,
    phones,
    description: contact.description ?? undefined,
    externalSystemIds: contact.externalSystemIds,
    preferredMethodOfContact: contact.preferredMethodOfContact ?? 'unset',
    primaryEmailIndex: primaryEmailIndex >= 0 ? primaryEmailIndex : null,
    primaryPhoneIndex: primaryPhoneIndex >= 0 ? primaryPhoneIndex : null,
  }

  log.info('contactForm', contactForm)

  return contactForm
}

export function mapFormContactToApiContactRequest(
  contact: ContactForm,
  metadata?: Contact['metadata']
): Omit<CreateContactRequest, 'requestActor'> {
  const name: ContactName = {
    id: contact.nameId,
    givenName: contact.givenName,
    familyName: !!contact.familyName ? { surname: contact.familyName } : undefined,
    rawName: `${contact.givenName}${!!contact.familyName ? ` ${contact.familyName}` : ''}`,
  }
  // init comm info from phone entries
  const phoneCommunicationInfo = mapPhonesToContact(contact.phones, contact.primaryPhoneIndex, [])
  const communicationInformation = mapEmailsToContact(
    contact.emails,
    contact.primaryEmailIndex,
    // build from above comm info
    phoneCommunicationInfo
  )

  const request: Omit<CreateContactRequest, 'requestActor'> = {
    roles: mapRolesToContact(contact.roles),
    name: [name],
    addresses: mapAddressesToContact(contact.addresses),
    communicationInformation,
    metadata: Object.fromEntries(
      Object.entries(metadata ? { ...metadata } : { dataSource: 'CSR' }).filter(
        ([__, value]) => !!value
      )
    ) as Contact['metadata'],
    description: contact.description || undefined,
    preferredMethodOfContact: contact.preferredMethodOfContact,
    externalSystemIds: contact.externalSystemIds,
  }

  log.info('update contact request', request)

  return request
}
