import { useGetAuth0UserNames } from '@eigtech/ui-shared-api'
import { ComposedModalWrapperProps, formatArrayAsStringList } from '@eigtech/ui-shared-dave'
import { FormModal, createForm } from '@eigtech/ui-shared-forms'
import { map, uniq } from 'lodash-es'
import { useMemo } from 'react'
import { z } from 'zod'
import { useDetailedTimelineContext } from '../DetailedTimeline'
import { FilterFn } from './timelineFilterState'

export type CreatedByFilterModalProps = ComposedModalWrapperProps

export function CreatedByFilterModal(modalProps: CreatedByFilterModalProps) {
  const { timeline, setFilters } = useDetailedTimelineContext()

  const createdBy = useMemo(() => {
    const chronicles = timeline?.timeline.chronicles ?? []
    const allCreatedBy = map(chronicles, 'createdBy').filter(
      (createdBy): createdBy is string => !!createdBy
    )
    return uniq(allCreatedBy)
  }, [timeline?.timeline.chronicles])

  const userNamesQueries = useGetAuth0UserNames(createdBy)

  const createdByOptions = useMemo(
    () =>
      userNamesQueries.map(({ data }, index) => ({
        label: data,
        value: createdBy[index],
      })),
    [createdBy, userNamesQueries]
  )

  const form = useForm()

  function handleSubmit(data: FormData) {
    const values = data.createdBy.map(({ value }) => value)
    const filter: FilterFn = (chronicle) =>
      !!chronicle.createdBy && values.includes(chronicle.createdBy)

    const labels = data.createdBy.map(({ label }) => `"${label}"`)

    setFilters({
      type: 'update',
      payload: {
        filter,
        label: `Created By is ${formatArrayAsStringList(labels, { type: 'disjunction' })}`,
        type: 'createdBy',
      },
    })

    modalProps.onClose()
  }

  return (
    <FormModal {...modalProps} form={form} title="Add Created By Filter" onSubmit={handleSubmit}>
      <ReactSelectField isMulti label="Select a User" name="createdBy" options={createdByOptions} />
    </FormModal>
  )
}

const FormSchema = z.object({
  createdBy: z.object({ label: z.string(), value: z.string() }).array(),
})

type FormData = z.infer<typeof FormSchema>

const { useForm, ReactSelectField } = createForm(FormSchema, 'eventFilterForm')
