import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { BookCornSchema } from '../../../base/books.js'

export const CreateClaimBookRequestBodySchema = z.object({
  claimNumber: z.string(),
})
export const CreateClaimBookRequestHeadersSchema =
  ApiLambdalerAuthHeaderWithRequestActorSchema.pick({ requestActor: true })
export const CreateClaimBookRequestSchema = CreateClaimBookRequestBodySchema.merge(
  CreateClaimBookRequestHeadersSchema
)
export const CreateClaimBookResponseSchema = z.object({
  bookId: BookCornSchema,
})

export type CreateClaimBookRequestBody = z.infer<typeof CreateClaimBookRequestBodySchema>
export type CreateClaimBookRequest = z.infer<typeof CreateClaimBookRequestSchema>
export type CreateClaimBookResponse = z.infer<typeof CreateClaimBookResponseSchema>

export interface CreateClaimBookHandler
  extends Handler<CreateClaimBookRequest, CreateClaimBookResponse> {}

export const CreateClaimBookApiSchema: ApiSchema = {
  body: CreateClaimBookRequestBodySchema,
  headers: CreateClaimBookRequestHeadersSchema,
  response: CreateClaimBookResponseSchema,
}
