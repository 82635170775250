import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { ContactCornSchema, ContactSchema } from '../../../base/contacts.js'

export const DeleteContactRequestPathSchema = z.object({
  contactId: ContactCornSchema,
})
export const DeleteContactRequestHeadersSchema = ApiLambdalerAuthHeaderWithRequestActorSchema.pick({
  requestActor: true,
})
export const DeleteContactRequestQuerySchema = z.object({ force: z.literal('true').optional() })
export const DeleteContactRequestSchema = DeleteContactRequestPathSchema.merge(
  DeleteContactRequestQuerySchema
).merge(DeleteContactRequestHeadersSchema)
export const DeleteContactResponseSchema = z.object({
  contact: ContactSchema.optional(),
})

export type DeleteContactRequestHeaders = z.infer<typeof DeleteContactRequestHeadersSchema>
export type DeleteContactRequestPathParams = z.infer<typeof DeleteContactRequestPathSchema>
export type DeleteContactRequestQueryParams = z.infer<typeof DeleteContactRequestQuerySchema>
export type DeleteContactRequest = z.infer<typeof DeleteContactRequestSchema>
export type DeleteContactResponse = z.infer<typeof DeleteContactResponseSchema>

export interface DeleteContactHandler
  extends Handler<DeleteContactRequest, DeleteContactResponse> {}

export const DeleteContactApiSchema = {
  headers: DeleteContactRequestHeadersSchema,
  params: DeleteContactRequestPathSchema,
  query: DeleteContactRequestQuerySchema,
  response: DeleteContactResponseSchema,
} satisfies ApiSchema
