import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import {
  ContactCornSchema,
  ContactMergeStrategySchema,
  ContactSchema,
} from '../../../base/contacts.js'

export const MergeContactRequestBodySchema = z.object({
  base: ContactCornSchema,
  other: ContactCornSchema,
  dryRun: z.boolean().optional(),
  mergeStrategy: ContactMergeStrategySchema,
})

export const MergeContactHeadersSchema = ApiLambdalerAuthHeaderWithRequestActorSchema.pick({
  requestActor: true,
})
export const MergeContactRequestSchema =
  MergeContactRequestBodySchema.merge(MergeContactHeadersSchema)

export const MergeContactResponseSchema = z.object({
  mergedContact: ContactSchema.optional(),
  mergedBooks: z.string().array(),
})

export type MergeContactRequestHeaders = z.infer<typeof MergeContactHeadersSchema>
export type MergeContactRequestBodyParams = z.infer<typeof MergeContactRequestBodySchema>
export type MergeContactRequest = z.infer<typeof MergeContactRequestSchema>

export type MergeContactResponse = z.infer<typeof MergeContactResponseSchema>

export interface MergeContactHandler extends Handler<MergeContactRequest, MergeContactResponse> {}
export const MergeContactApiSchema = {
  body: MergeContactRequestBodySchema,
  headers: MergeContactHeadersSchema,
  response: MergeContactResponseSchema,
} satisfies ApiSchema
