import { MeetingDetailsSchema } from '@eigtech/meetings-types'
import {
  ClaimAssignmentDetailsSchema,
  ClaimClosedDetailsSchema,
  ClaimDefaultChronicleTypeEnum,
  ClaimDefaultChronicleTypeSchema,
  ClaimEstimateReceivedDetailsSchema,
  ClaimEstimateReviewedDetailsSchema,
  ClaimInspectionCanceledDetailsSchema,
  ClaimInspectionCompletedDetailsSchema,
  ClaimInspectionScheduledDetailsSchema,
  ClaimLossOfUseDetailsSchema,
  ClaimLossOfUseReceiptAddedDetailsSchema,
  ClaimLossOfUseReceiptRemovedDetailsSchema,
  ClaimReInspectionRequestedDetailsSchema,
  ClaimReopenedDetailsSchema,
  ClaimReviewCompletedDetailsSchema,
  ClaimReviewRequestedDetailsSchema,
  ClaimUnassignmentDetailsSchema,
  GetTimelineRequest,
} from '@eigtech/summarizer-types'
import { OmitAuthHeaders } from '@eigtech/ui-shared-api'
import { getClaimTimelineEventLabel } from '@eigtech/ui-shared-claims'
import { StackProps, forwardRef } from '@eigtech/ui-shared-dave'
import { exhaustive } from 'exhaustive'
import { ReactNode } from 'react'
import { HiDocumentAdd } from 'react-icons/hi'
import { MdAssignmentInd, MdAssignmentLate, MdClose, MdLocationSearching } from 'react-icons/md'
import { DetailedTimeline } from '../DetailedTimeline'
import { DetailedTimelineFilters } from '../DetailedTimelineFilters'
import { DetailedTimelineView } from '../DetailedTimelineView'
import { makeDetailsComponent } from '../TimelineEventDetails'
import {
  AssignedDetails,
  ClaimMeetingDetails,
  ClosedDetails,
  EstimateReceivedDetails,
  EstimateReviewedDetails,
  InspectionDetails,
  LossOfUseDetails,
  LossOfUseReceiptDetails,
  LossOfUseStatusDetails,
  ReopenedDetails,
  ReviewCompletedDetails,
  ReviewRequestedDetails,
  UnassignedDetails,
} from './ClaimTimelineDetails'

export type ClaimDetailedTimelineProps = StackProps &
  Pick<OmitAuthHeaders<GetTimelineRequest>, 'timelineType'> & { claimNumber: string }

export const ClaimDetailedTimeline = forwardRef<ClaimDetailedTimelineProps, 'div'>(
  function ClaimDetailedTimeline({ claimNumber, timelineType, ...props }, ref) {
    return (
      <DetailedTimeline entityId={claimNumber} entityType="claim" timelineType={timelineType}>
        <DetailedTimelineFilters filterableEvents={filterableEvents} />
        <DetailedTimelineView
          ref={ref}
          {...props}
          eventComponents={claimEventComponents}
          getPointIcon={(event) => getPointIcon(event as ClaimDefaultChronicleTypeEnum)}
        />
      </DetailedTimeline>
    )
  }
)

const filterableEvents = ClaimDefaultChronicleTypeSchema.options.map((value) => ({
  value,
  label: getClaimTimelineEventLabel(value),
}))

const getPointIcon = (event: ClaimDefaultChronicleTypeEnum) =>
  exhaustive(event, {
    canceled: () => MdClose,
    carrierApprovedEstimate: () => MdLocationSearching,
    carrierApprovedEstimateWithExceptions: () => MdLocationSearching,
    carrierAssignedToClaim: () => MdAssignmentInd,
    carrierRejected: () => MdLocationSearching,
    carrierRejectedEstimate: () => MdLocationSearching,
    carrierReviewed: () => MdLocationSearching,
    carrierReviewedWithExceptions: () => MdLocationSearching,
    carrierUnassignedFromClaim: () => MdAssignmentLate,
    catCodeUpdated: () => MdLocationSearching,
    claimMeetingEnded: () => MdLocationSearching,
    clientApproved: () => MdLocationSearching,
    clientRejected: () => MdLocationSearching,
    closed: () => MdClose,
    coordinatorAssignedToClaim: () => MdAssignmentInd,
    coordinatorUnassignedFromClaim: () => MdAssignmentLate,
    customerContactedEmail: () => MdLocationSearching,
    customerContactedLvm: () => MdLocationSearching,
    customerNoContact: () => MdLocationSearching,
    dateReceived: () => MdLocationSearching,
    estimateReceived: () => HiDocumentAdd,
    estimateRevisionRequested: () => MdLocationSearching,
    estimateSentToCarrier: () => MdLocationSearching,
    fieldAdjusterAssignedToClaim: () => MdAssignmentInd,
    fieldAdjusterContactedCustomer: () => MdLocationSearching,
    fieldAdjusterCorrectionUploaded: () => MdLocationSearching,
    fieldAdjusterEstimateUploaded: () => MdLocationSearching,
    inspectionCanceled: () => MdClose,
    inspectionCompleted: () => MdLocationSearching,
    inspectionScheduled: () => MdLocationSearching,
    fieldAdjusterUnassignedFromClaim: () => MdAssignmentLate,
    inAssignQueue: () => MdLocationSearching,
    initialCustomerContactAttempted: () => MdLocationSearching,
    initialCustomerContactFailed: () => MdLocationSearching,
    initialCustomerContactSuccess: () => MdLocationSearching,
    invalidContactInformation: () => MdLocationSearching,
    inspectionTechnicianAssignedToClaim: () => MdAssignmentInd,
    inspectionTechnicianUnassignedFromClaim: () => MdAssignmentLate,
    jobAssignedToClaim: () => MdAssignmentInd,
    jobCompleted: () => MdLocationSearching,
    jobContracted: () => MdLocationSearching,
    jobNotSold: () => MdLocationSearching,
    jobSold: () => MdLocationSearching,
    jobStarted: () => MdLocationSearching,
    jobUnassignedFromClaim: () => MdAssignmentLate,
    legalRepAssignedToClaim: () => MdAssignmentInd,
    legalRepUnassignedFromClaim: () => MdAssignmentLate,
    lossDate: () => MdLocationSearching,
    lossOfUseAdded: () => MdLocationSearching,
    lossOfUseApproved: () => MdLocationSearching,
    lossOfUseCanceled: () => MdLocationSearching,
    lossOfUseDenied: () => MdLocationSearching,
    lossOfUsePending: () => MdLocationSearching,
    lossOfUseReceiptAdded: () => MdLocationSearching,
    lossOfUseReceiptRemoved: () => MdLocationSearching,
    lossSeverityUpdated: () => MdLocationSearching,
    lossTypeUpdated: () => MdLocationSearching,
    markedForPublication: () => MdLocationSearching,
    policyHolderAssignedToClaim: () => MdAssignmentInd,
    policyHolderUnassignedFromClaim: () => MdAssignmentLate,
    primaryContactAssignedToClaim: () => MdAssignmentInd,
    primaryContactUnassignedFromClaim: () => MdAssignmentLate,
    projectManagerAssignedToClaim: () => MdAssignmentInd,
    projectManagerUnassignedFromClaim: () => MdAssignmentLate,
    qaApproved: () => MdLocationSearching,
    qaApprovedPreliminaryReport: () => MdLocationSearching,
    qaApprovedSupplementReport: () => MdLocationSearching,
    qaRejected: () => MdLocationSearching,
    reInspectionRequested: () => MdLocationSearching,
    reopened: () => MdLocationSearching,
    reviewCompleted: () => MdLocationSearching,
    reviewerAssignedToClaim: () => MdAssignmentInd,
    reviewerUnassignedFromClaim: () => MdAssignmentLate,
    reviewRequested: () => MdLocationSearching,
    surveySentToCustomer: () => MdLocationSearching,
    _: () => MdLocationSearching,
  })

const claimEventComponents = {
  //#region Claim Lifecycle
  closed: makeDetailsComponent({
    Component: ClosedDetails,
    Schema: ClaimClosedDetailsSchema,
  }),
  reopened: makeDetailsComponent({
    Component: ReopenedDetails,
    Schema: ClaimReopenedDetailsSchema,
  }),
  //#endregion

  //#region Claim Assigned
  carrierAssignedToClaim: makeDetailsComponent({
    Component: AssignedDetails,
    Schema: ClaimAssignmentDetailsSchema,
  }),
  coordinatorAssignedToClaim: makeDetailsComponent({
    Component: AssignedDetails,
    Schema: ClaimAssignmentDetailsSchema,
  }),
  fieldAdjusterAssignedToClaim: makeDetailsComponent({
    Component: AssignedDetails,
    Schema: ClaimAssignmentDetailsSchema,
  }),
  inspectionTechnicianAssignedToClaim: makeDetailsComponent({
    Component: AssignedDetails,
    Schema: ClaimAssignmentDetailsSchema,
  }),
  jobAssignedToClaim: makeDetailsComponent({
    Component: AssignedDetails,
    Schema: ClaimAssignmentDetailsSchema,
  }),
  legalRepAssignedToClaim: makeDetailsComponent({
    Component: AssignedDetails,
    Schema: ClaimAssignmentDetailsSchema,
  }),
  policyHolderAssignedToClaim: makeDetailsComponent({
    Component: AssignedDetails,
    Schema: ClaimAssignmentDetailsSchema,
  }),
  primaryContactAssignedToClaim: makeDetailsComponent({
    Component: AssignedDetails,
    Schema: ClaimAssignmentDetailsSchema,
  }),
  projectManagerAssignedToClaim: makeDetailsComponent({
    Component: AssignedDetails,
    Schema: ClaimAssignmentDetailsSchema,
  }),
  reviewerAssignedToClaim: makeDetailsComponent({
    Component: AssignedDetails,
    Schema: ClaimAssignmentDetailsSchema,
  }),
  //#endregion

  //#region Claim Unassigned
  carrierUnassignedFromClaim: makeDetailsComponent({
    Component: UnassignedDetails,
    Schema: ClaimUnassignmentDetailsSchema,
  }),
  coordinatorUnassignedFromClaim: makeDetailsComponent({
    Component: UnassignedDetails,
    Schema: ClaimUnassignmentDetailsSchema,
  }),
  fieldAdjusterUnassignedFromClaim: makeDetailsComponent({
    Component: UnassignedDetails,
    Schema: ClaimUnassignmentDetailsSchema,
  }),
  inspectionTechnicianUnassignedFromClaim: makeDetailsComponent({
    Component: UnassignedDetails,
    Schema: ClaimUnassignmentDetailsSchema,
  }),
  jobUnassignedFromClaim: makeDetailsComponent({
    Component: UnassignedDetails,
    Schema: ClaimUnassignmentDetailsSchema,
  }),
  legalRepUnassignedFromClaim: makeDetailsComponent({
    Component: UnassignedDetails,
    Schema: ClaimUnassignmentDetailsSchema,
  }),
  policyHolderUnassignedFromClaim: makeDetailsComponent({
    Component: UnassignedDetails,
    Schema: ClaimUnassignmentDetailsSchema,
  }),
  primaryContactUnassignedFromClaim: makeDetailsComponent({
    Component: UnassignedDetails,
    Schema: ClaimUnassignmentDetailsSchema,
  }),
  projectManagerUnassignedFromClaim: makeDetailsComponent({
    Component: UnassignedDetails,
    Schema: ClaimUnassignmentDetailsSchema,
  }),
  reviewerUnassignedFromClaim: makeDetailsComponent({
    Component: UnassignedDetails,
    Schema: ClaimUnassignmentDetailsSchema,
  }),
  //#endregion
  //#region Inspection
  inspectionCanceled: makeDetailsComponent({
    Component: InspectionDetails,
    Schema: ClaimInspectionCanceledDetailsSchema,
  }),
  inspectionCompleted: makeDetailsComponent({
    Component: InspectionDetails,
    Schema: ClaimInspectionCompletedDetailsSchema,
  }),
  inspectionScheduled: makeDetailsComponent({
    Component: InspectionDetails,
    Schema: ClaimInspectionScheduledDetailsSchema,
  }),
  reInspectionRequested: makeDetailsComponent({
    Component: InspectionDetails,
    Schema: ClaimReInspectionRequestedDetailsSchema,
  }),
  //#endregion

  //#region Loss of use
  lossOfUseAdded: makeDetailsComponent({
    Component: LossOfUseDetails,
    Schema: ClaimLossOfUseDetailsSchema,
  }),
  lossOfUseApproved: makeDetailsComponent({
    Component: LossOfUseStatusDetails,
    Schema: ClaimLossOfUseDetailsSchema,
  }),
  lossOfUseCanceled: makeDetailsComponent({
    Component: LossOfUseStatusDetails,
    Schema: ClaimLossOfUseDetailsSchema,
  }),
  lossOfUseDenied: makeDetailsComponent({
    Component: LossOfUseStatusDetails,
    Schema: ClaimLossOfUseDetailsSchema,
  }),
  lossOfUsePending: makeDetailsComponent({
    Component: LossOfUseStatusDetails,
    Schema: ClaimLossOfUseDetailsSchema,
  }),
  lossOfUseReceiptAdded: makeDetailsComponent({
    Component: LossOfUseReceiptDetails,
    Schema: ClaimLossOfUseReceiptAddedDetailsSchema,
  }),
  lossOfUseReceiptRemoved: makeDetailsComponent({
    Component: LossOfUseReceiptDetails,
    Schema: ClaimLossOfUseReceiptRemovedDetailsSchema,
  }),
  //#endregion

  //#region Estimates
  carrierRejected: makeDetailsComponent({
    Component: EstimateReviewedDetails,
    Schema: ClaimEstimateReviewedDetailsSchema,
  }),
  carrierReviewed: makeDetailsComponent({
    Component: EstimateReviewedDetails,
    Schema: ClaimEstimateReviewedDetailsSchema,
  }),
  carrierReviewedWithExceptions: makeDetailsComponent({
    Component: EstimateReviewedDetails,
    Schema: ClaimEstimateReviewedDetailsSchema,
  }),
  clientApproved: makeDetailsComponent({
    Component: EstimateReviewedDetails,
    Schema: ClaimEstimateReviewedDetailsSchema,
  }),
  clientRejected: makeDetailsComponent({
    Component: EstimateReviewedDetails,
    Schema: ClaimEstimateReviewedDetailsSchema,
  }),
  estimateReceived: makeDetailsComponent({
    Component: EstimateReceivedDetails,
    Schema: ClaimEstimateReceivedDetailsSchema,
  }),
  qaApproved: makeDetailsComponent({
    Component: EstimateReviewedDetails,
    Schema: ClaimEstimateReviewedDetailsSchema,
  }),
  qaRejected: makeDetailsComponent({
    Component: EstimateReviewedDetails,
    Schema: ClaimEstimateReviewedDetailsSchema,
  }),
  //#endregion

  //#region Reviews
  reviewRequested: makeDetailsComponent({
    Component: ReviewRequestedDetails,
    Schema: ClaimReviewRequestedDetailsSchema,
  }),
  reviewCompleted: makeDetailsComponent({
    Component: ReviewCompletedDetails,
    Schema: ClaimReviewCompletedDetailsSchema,
  }),
  //#endregion

  //#region Meetings
  claimMeetingEnded: makeDetailsComponent({
    Component: ClaimMeetingDetails,
    Schema: MeetingDetailsSchema,
  }),
  //#endregion Meetings
} satisfies Record<
  HandledClaimEvent,
  (props: { details: Record<string, unknown>; entityId: string }) => ReactNode
>
// const claimEventComponents = {
//   estimateReceived: makeDetailsComponent({
//     Component: EstimateReceivedDetails,
//     Schema: ClaimEstimateReceivedEventDetailsSchema,
//   }),
// } satisfies Record<
//   HandledClaimEvent,
//   (props: { details: Record<string, unknown> }) => ReactNode
// >

// KC: Each type of ‘handled’ claim event needs removed from this (excluded) list
type HandledClaimEvent = Exclude<
  ClaimDefaultChronicleTypeEnum,
  | 'canceled'
  | 'carrierApprovedEstimate'
  | 'carrierApprovedEstimateWithExceptions'
  | 'carrierRejectedEstimate'
  | 'catCodeUpdated'
  | 'customerContactedEmail'
  | 'customerContactedLvm'
  | 'customerNoContact'
  | 'dateReceived'
  | 'estimateRevisionRequested'
  | 'estimateSentToCarrier'
  | 'fieldAdjusterContactedCustomer'
  | 'fieldAdjusterCorrectionUploaded'
  | 'fieldAdjusterEstimateUploaded'
  | 'inAssignQueue'
  | 'initialCustomerContactAttempted'
  | 'initialCustomerContactFailed'
  | 'initialCustomerContactSuccess'
  | 'invalidContactInformation'
  | 'jobCompleted'
  | 'jobContracted'
  | 'jobNotSold'
  | 'jobSold'
  | 'jobStarted'
  | 'lossDate'
  | 'lossSeverityUpdated'
  | 'lossTypeUpdated'
  | 'markedForPublication'
  | 'qaApprovedPreliminaryReport'
  | 'qaApprovedSupplementReport'
  | 'surveySentToCustomer'
>
