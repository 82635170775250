import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { ContactSchema } from '../../../base/contacts.js'
import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types'

export const UpsertContactDescriptionRequestBodySchema = z.object({
  description: z.string().min(1),
})
export const UpsertContactDescriptionRequestPathSchema = z.object({
  contactId: ContactSchema.shape.contactId,
})
export const UpsertContactDescriptionRequestHeadersSchema =
  ApiLambdalerAuthHeaderWithRequestActorSchema.pick({ requestActor: true })

export const UpsertContactDescriptionRequestSchema =
  UpsertContactDescriptionRequestBodySchema.merge(UpsertContactDescriptionRequestPathSchema).merge(
    UpsertContactDescriptionRequestHeadersSchema
  )

export const UpsertContactDescriptionResponseSchema = z.void()

export type UpsertContactDescriptionRequestBody = z.infer<
  typeof UpsertContactDescriptionRequestBodySchema
>

export type UpsertContactDescriptionRequestPath = z.infer<
  typeof UpsertContactDescriptionRequestPathSchema
>
export type UpsertContactDescriptionRequest = z.infer<typeof UpsertContactDescriptionRequestSchema>

export type UpsertContactDescriptionResponse = z.infer<
  typeof UpsertContactDescriptionResponseSchema
>

export interface UpsertContactDescriptionHandler
  extends Handler<UpsertContactDescriptionRequest, UpsertContactDescriptionResponse> {}

export const UpsertContactDescriptionApiSchema: ApiSchema = {
  body: UpsertContactDescriptionRequestBodySchema,
  headers: UpsertContactDescriptionRequestHeadersSchema,
  params: UpsertContactDescriptionRequestPathSchema,
}
