import { DeleteContactRequest, DeleteContactResponse } from '@eigtech/contacts-types'
import { contextualRemove, useMutation } from '@eigtech/ui-shared-api'
import { contactBasePath } from './constants'
import { useInvalidateBooksDetails } from './getBooks'
import { useInvalidateContact } from './getContacts'

const deleteContact = async ({ contactId }: Omit<DeleteContactRequest, 'requestActor'>) => {
  await contextualRemove<DeleteContactResponse>(`${contactBasePath}/${contactId}`, null)
}

export function useDeleteContact() {
  const invalidateContact = useInvalidateContact()
  const invalidateBooks = useInvalidateBooksDetails()

  return useMutation({
    mutationFn: deleteContact,
    onSuccess(__, { contactId }) {
      invalidateContact(contactId)
      invalidateBooks()
    },
  })
}
